import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchDocuments, setSelectedNode } from '../Uploader/redux/actions/documentActions';
import buildHierarchy from '../Uploader/redux/actions/buildHierarchy';
import { Folder as FolderIcon, Description as FileIcon, Height } from '@mui/icons-material';
import ImageIcon from '@mui/icons-material/Image';
import { Box } from "@mui/material";
import pdfDummy from '../../assets/img/images.png'
const FolderViewer = () => {
    const dispatch = useDispatch();
    const { fetchDocumentsLoading, error, documents, parentSelected } = useSelector((state) => state.documents);
    const [itemsArray, setItemsArray] = useState([]);
    const [currentItemsArray, setCurrentItemsArray] = useState([]);
    const [folderStack, setFolderStack] = useState([]);
    const [children, setChildren] = useState([]);

    useEffect(() => {
        dispatch(fetchDocuments());
    }, [dispatch]);

    useEffect(() => {
        if (!fetchDocumentsLoading && documents.length > 0) {

            const hierarchy = buildHierarchy(documents);
            setItemsArray(hierarchy);
        }
    }, [fetchDocumentsLoading, documents]);

    const data = [...itemsArray];
    function findChildrenById(arr, id) {
        for (const item of arr) {
            if (item._id === id) {
                return item.children || [];
            }
            if (item.children && item.children.length > 0) {
                const result = findChildrenById(item.children, id);
                if (result.length > 0) {
                    return result;
                }
            }
        }
        return [];
    }


    const handleItemChange = (item) => {
        if (item.dtype !== "folder") {
            dispatch(setSelectedNode(item._id));
        } else {
            if (currentItemsArray) {
                setFolderStack(prevStack => [...prevStack, currentItemsArray]);
            }
            const foundChildren = findChildrenById(data, item._id);
            setCurrentItemsArray(foundChildren)
            setChildren(foundChildren);
        }
    };

    useEffect(() => {
        if (parentSelected) {
            if (currentItemsArray) {
                setFolderStack(prevStack => [...prevStack, currentItemsArray]);
            }
            const foundChildren = findChildrenById(data, parentSelected);
            setCurrentItemsArray(foundChildren)
            setChildren(foundChildren);
        }
    }, [parentSelected]);

    const handleGoBack = () => {
        if (folderStack.length > 0) {
            const previousFolderId = folderStack.pop();
            dispatch(setSelectedNode(previousFolderId._id));
            const foundChildren = findChildrenById(data, previousFolderId);
            setChildren(foundChildren);
            setCurrentItemsArray(previousFolderId);
            setFolderStack([...folderStack]);
        }
    };

    if (fetchDocumentsLoading) {
        return <div>Loading documents...</div>;
    }

    if (error) {
        return <div>Error loading documents: {error.message}</div>;
    }

    const fileIcon = (dtype) => {
        switch (dtype) {
            case 'folder':
                return <FolderIcon style={{ color: "#FFCC00", marginRight: '8px' }} />;
            case 'pdf':
                return <FileIcon style={{ color: "#D50000", marginRight: '8px' }} />;
            default:
                return '';
        }
    };


    return (
        <div>
            <div>
                <button onClick={handleGoBack} disabled={folderStack.length === 0}>
                    Go Back
                </button>
            </div>
            <Box p={3} >
                {(currentItemsArray.length > 0 && parentSelected) ? (
                    currentItemsArray.map((item, index) => (
                        <div   style={{ display:  'inline-flex',flexDirection:"column", alignItems: "top", cursor: "pointer", marginLeft: 4, marginTop: 10 }}>

                            <div
                                key={"doc_" + index}
                                className="thisischild"
                                style={{ display: item.pathUrl ? "inline-flex" : 'flex', alignItems: "top", cursor: "pointer", marginLeft: 4, marginTop: 10 }}
                                onClick={() => handleItemChange(item)}
                            >
                                {fileIcon(item.dtype)}
                                {
                                    (item.pathUrl !== '' && item.pathUrl !== null) ?
                                        <Box style={{ width: '100px', display: 'inline-flex', backgroundColor: '#757575', height: '100px', overflow: 'hidden', justifyContent: 'center', border: '1px solid #d5d5d5', borderRadius: '8px' }}>
                                            <img src={item.dtype === 'application/pdf' ? pdfDummy : item.pathUrl} /></Box> :

                                        <a
                                            style={{ width: "80%", marginLeft: "3px", cursor: "pointer" }}
                                            // href={item.pathUrl} 
                                            target="_new"
                                            rel="noopener noreferrer"
                                        >
                                            {item.fileKey !== "" ? item.fileKey : item.name}
                                        </a>
                                }
                            </div>
                            {item.dtype !== "folder" ? <div style={{width:"100px",fontSize:"13px",textAlign:"center",paddingTop:"2px",textWrap:"wrap"}}>{item.name}</div> : ""}
                            </div>

                    ))
                ) : (
                    <div> {'This folder is empty'}</div>
                )}
            </Box>
        </div>
    );
};

export default FolderViewer;
