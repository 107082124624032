import CompaniesModalContent from './CompaniesModalContent';
import ReferralPartnersModalContent from './ReferralPartnersModalContent'

interface Props {
    closeMe: (data: any) => void;
    currentRow?: any;
    detail?: boolean
    type: any,
    location?: any
}

function ReferralPartnersModal(props: Props) {
    const { type, closeMe, currentRow, location } = props

    if (type === "Referral Partners") return (
        <ReferralPartnersModalContent closeMe={closeMe} currentRow={currentRow || null} type={type} location={location} />
    )
    if (type === 'Companies') return (
        <CompaniesModalContent closeMe={closeMe} currentRow={currentRow || null} type={type} location={location} />
    )
    return <></>
}

export default ReferralPartnersModal
